<template>
  <div>
    <!-- TABLE -->
    <b-table
      striped
      borderless
      responsive
      class="position-relative"
      :busy.sync="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      :items="payments"
      show-empty
      :empty-text="$t('errors.emptyTable.title')"
      :empty-filtered-text="$t('errors.emptyTable.title')"
      :fields="fields"
      :filter="filter"
      @filtered="onTableFiltered"
      :foot-clone="!!payments.length"
      no-footer-sorting
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2" />
          <strong>{{ $t("Carregant") }}...</strong>
        </div>
      </template>

      <!-- Column: localizator -->
      <template #cell(bookingLocalizator)="row">
        <b-booking-localizator
          :localizator="row.item.booking.localizator"
          :source="row.item.booking.source"
          :highlighted="row.item.booking.highlighted"
          :fictitious="row.item.booking.fictitious"
          :cancelled="row.item.booking.cancelled"
        />
        <div class="mt-25 text-nowrap">
          <small
            class="text-nowrap"
            :class="{
              'text-warning': !isCurrentYear(row.item.booking.checkin),
            }"
          >
            {{ formatDateStringToDate(row.item.booking.checkin) }} -
            {{ formatDateStringToDate(row.item.booking.checkout) }}
          </small>
        </div>
      </template>

      <!-- Column: accommodation -->
      <template #cell(accommodation)="row">
        <b-accommodation
          :uuid="row.item.booking.accommodation.uuid"
          :name="row.item.booking.accommodation.name"
        />
        <br />
        <small v-if="getBillingType(row.item.booking) === 'SUBURANCE'">
          <b-badge> Subarrendament </b-badge>
        </small>
      </template>

      <!-- Column: method -->
      <template #cell(method)="row">
        {{ getPaymentMethodName(row.value) }}
      </template>

      <!-- Column: localizator -->
      <template #cell(localizator)="row">
        <span v-if="row.value">{{ row.value }}</span>
      </template>

      <!-- Column: bookingSum -->
      <template #cell(bookingSum)="row">
        <template v-if="row.item.bookingSum || row.item.bookingSum === 0">
          <span v-if="row.item.bookingSum > 0">
            +{{ formatCurrency(row.item.bookingSum) }}
          </span>
          <span v-if="row.item.bookingSum < 0">
            {{ formatCurrency(row.item.bookingSum) }}
          </span>
          <span v-if="row.item.bookingSum === 0"> 0 € </span>
        </template>
        <span v-else> - </span>
      </template>

      <!-- Column: securityDepositSum -->
      <template #cell(securityDepositSum)="row">
        <template
          v-if="
            row.item.securityDepositSum || row.item.securityDepositSum === 0
          "
        >
          <span v-if="row.item.securityDepositSum > 0">
            +{{ formatCurrency(row.item.securityDepositSum) }}
          </span>
          <span v-if="row.item.securityDepositSum < 0">
            {{ formatCurrency(row.item.securityDepositSum) }}
          </span>
          <span v-if="row.item.securityDepositSum === 0"> 0 € </span>
        </template>
        <span v-else> - </span>
      </template>

      <!-- Column: touristTaxSum -->
      <template #cell(touristTaxSum)="row">
        <template v-if="row.item.touristTaxSum || row.item.touristTaxSum === 0">
          <div :id="`tourist-tax-description-${row.item.customId}-container`">
            <div :id="`tourist-tax-description-${row.item.customId}`">
              <span v-if="row.item.touristTaxSum > 0">
                +{{ formatCurrency(row.item.touristTaxSum) }}
              </span>
              <span v-if="row.item.touristTaxSum < 0">
                {{ formatCurrency(row.item.touristTaxSum) }}
              </span>
              <span v-if="row.item.touristTaxSum === 0"> 0 € </span>
            </div>
          </div>
        </template>
        <span v-else> - </span>
        <b-popover
          v-if="itemHasTouristTaxDescription(row.item)"
          title="Descripció Impost Turístic"
          :target="`tourist-tax-description-${row.item.customId}`"
          triggers="hover"
          placement="left"
          :container="`tourist-tax-description-${row.item.customId}-container`"
        >
          <ul>
            <li
              v-for="(bookingService, index) in row.item.touristTaxDescriptions"
              :key="`popover-tourist-tax-${index}`"
            >
              {{ getBookingServiceName(bookingService) }}
              <ul>
                <li>
                  PVP:
                  {{
                    getBookingServicePvpPrice(
                      bookingService,
                      row.item.bookingNights
                    ) || "No definit"
                  }}
                </li>
                <li>
                  Propietari:
                  {{
                    getBookingServiceCostPrice(
                      bookingService,
                      row.item.bookingNights
                    ) || "No definit"
                  }}
                </li>
              </ul>
            </li>
          </ul>
        </b-popover>
      </template>

      <!-- Column: servicesSum -->
      <template #cell(servicesSum)="row">
        <template v-if="row.item.servicesSum || row.item.servicesSum === 0">
          <div :id="`services-description-${row.item.customId}-container`">
            <div :id="`services-description-${row.item.customId}`">
              <span v-if="row.item.servicesSum > 0">
                +{{ formatCurrency(row.item.servicesSum) }}
              </span>
              <span v-if="row.item.servicesSum < 0">
                {{ formatCurrency(row.item.servicesSum) }}
              </span>
              <span v-if="row.item.servicesSum === 0"> 0 € </span>
            </div>
          </div>
        </template>
        <span v-else> - </span>
        <b-popover
          v-if="itemHasServicesDescriptions(row.item)"
          title="Descripció serveis"
          :target="`services-description-${row.item.customId}`"
          triggers="hover"
          placement="left"
          :container="`services-description-${row.item.customId}-container`"
        >
          <ul>
            <li
              v-for="(bookingService, index) in row.item.servicesDescriptions"
              :key="`popover-services-${index}`"
            >
              {{ getBookingServiceName(bookingService) }}
              <ul>
                <li>
                  PVP:
                  {{
                    getBookingServicePvpPrice(
                      bookingService,
                      row.item.bookingNights
                    ) || "No definit"
                  }}
                </li>
                <li>
                  Propietari:
                  {{
                    getBookingServiceCostPrice(
                      bookingService,
                      row.item.bookingNights
                    ) || "No definit"
                  }}
                </li>
              </ul>
            </li>
          </ul>
        </b-popover>
      </template>

      <!-- Column: totalSum -->
      <template #cell(totalSum)="row">
        <template v-if="row.item.totalSum || row.item.totalSum === 0">
          <span v-if="row.item.totalSum > 0" class="text-success">
            +{{ formatCurrency(row.item.totalSum) }}
          </span>
          <span v-if="row.item.totalSum < 0" class="text-danger">
            {{ formatCurrency(row.item.totalSum) }}
          </span>
          <span v-if="row.item.totalSum === 0"> 0 € </span>
        </template>
        <span v-else> - </span>
      </template>

      <!-- Column: status -->
      <template #cell(status)="row">
        <b-badge pill :variant="getClientPaymentStatusColor(row.value)">
          {{ getClientPaymentStatusName(row.value) }}
        </b-badge>
      </template>

      <!-- Column: actions -->
      <template #cell(actions)="row">
        <div class="d-flex justify-content-center">
          <b-button
            v-b-tooltip.hover="
              `${
                row.detailsShowing ? 'Amaga' : 'Mostra'
              } detalls dels pagaments`
            "
            size="sm"
            class="btn-icon"
            variant="primary"
            @click="row.toggleDetails"
          >
            <feather-icon icon="ListIcon" />
          </b-button>

          <b-button
            v-if="row.item.status !== 'ACCOUNTED'"
            v-b-tooltip.hover="'Comptabilitzar pagament'"
            size="sm"
            class="btn-icon ml-50"
            variant="primary"
            @click="confirmAccountPayments(row.item)"
          >
            <feather-icon icon="CheckIcon" />
          </b-button>
        </div>
      </template>

      <!-- Row details -->
      <template #row-details="row">
        <b-card>
          <b-table-simple>
            <b-thead>
              <b-tr>
                <b-th>Concepte</b-th>
                <b-th>Import</b-th>
                <b-th>Comentari</b-th>
                <b-th>Estat</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="clientPayment in row.item.clientPayments"
                :key="clientPayment.uuid"
              >
                <b-td>
                  {{
                    getClientPaymentScopeName(clientPayment.scope, $i18n.locale)
                  }}
                </b-td>
                <b-td>{{ formatCurrency(clientPayment.pvpAmount) }}</b-td>
                <b-td>
                  {{ clientPayment.comment || "Sense comentaris" }}
                </b-td>
                <b-td>
                  <b-badge
                    pill
                    :variant="getClientPaymentStatusColor(clientPayment.status)"
                  >
                    {{ getClientPaymentStatusName(clientPayment.status) }}
                  </b-badge>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </template>

      <!-- Footer -->
      <template #foot(bookingLocalizator)>
        <span />
      </template>
      <template #foot(accommodation)>
        <span />
      </template>
      <template #foot(method)>
        <span />
      </template>
      <template #foot(localizator)>
        <span />
      </template>
      <template #foot(bookingSum)>
        <span>{{ formatCurrency(paymentsBookingSum) }}</span>
      </template>
      <template #foot(securityDepositSum)>
        <span>{{ formatCurrency(paymentsSecurityDepositSum) }}</span>
      </template>
      <template #foot(touristTaxSum)>
        <span>{{ formatCurrency(paymentsTouristTaxSum) }}</span>
      </template>
      <template #foot(servicesSum)>
        <span>{{ formatCurrency(paymentsServicesSum) }}</span>
      </template>
      <template #foot(totalSum)>
        <span>{{ formatCurrency(paymentsTotalSum) }}</span>
      </template>
      <template #foot(status)>
        <span />
      </template>
      <template #foot(actions)>
        <span />
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BSpinner,
  BBadge,
  VBTooltip,
  VBPopover,
  BPopover,
  BButton,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
} from "bootstrap-vue";
import BBookingLocalizator from "@foravila-core/components/b-booking-localizator/BBookingLocalizator.vue";
import BAccommodation from "@foravila-core/components/b-accommodation/BAccommodation.vue";
import {
  formatCurrency,
  formatDateObjectToDatabaseDateTime,
  formatDateStringToDate,
} from "@/utils/formatters";
import {
  getBookingServiceClientTotalPriceText,
  getBookingServiceOwnerTotalPriceText,
  getClientPaymentScopeName,
  getClientPaymentStatusName,
  getPaymentMethodName,
  getServiceName,
  notifyError,
  notifySuccess,
} from "@/utils/methods";

export default {
  components: {
    BCard,
    BTable,
    BSpinner,
    BBadge,
    BBookingLocalizator,
    BAccommodation,
    BPopover,
    BButton,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover,
  },
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    filter: {
      type: String,
      default: null,
    },
    payments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isBusy: true,
      sortBy: "bookingLocalizator",
      sortDesc: false,
      fields: [
        {
          key: "bookingLocalizator",
          label: this.$t("Reserva nº"),
          sortable: true,
        },
        {
          key: "accommodation",
          label: this.$t("Allotjament"),
          sortable: true,
        },
        {
          key: "method",
          label: this.$t("Mètode"),
          sortable: true,
        },
        {
          key: "localizator",
          label: this.$t("Localitzador"),
          sortable: false,
        },
        {
          key: "bookingSum",
          label: this.$t("Reserva"),
          sortable: true,
        },
        {
          key: "securityDepositSum",
          label: this.$t("Fiança"),
          sortable: true,
        },
        {
          key: "touristTaxSum",
          label: this.$t("Impost turístic"),
          sortable: true,
        },
        {
          key: "servicesSum",
          label: this.$t("Serveis"),
          sortable: true,
        },
        {
          key: "totalSum",
          label: this.$t("Total"),
          sortable: true,
        },
        {
          key: "status",
          label: this.$t("Estat"),
          sortable: true,
        },
        { key: "actions", label: this.$t("Accions"), sortable: false },
      ],
      formatDateStringToDate,
      paymentsBookingSum: 0,
      paymentsSecurityDepositSum: 0,
      paymentsTouristTaxSum: 0,
      paymentsServicesSum: 0,
      paymentsTotalSum: 0,
      confirmAccountPaymentAlertConfig: {
        title: "Comptabilitzar pagament",
        text: "Estàs a punt de comptabilitzar aquest pagament, estàs segur/a?",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Comptabilitza",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
      },
    };
  },
  watch: {
    loading(loading) {
      this.isBusy = loading;
    },
    payments(payments) {
      this.updatePaymentsSums(payments);
    },
  },
  created() {
    this.updatePaymentsSums(this.payments);
  },
  methods: {
    confirmAccountPayments(item) {
      this.$swal(this.confirmAccountPaymentAlertConfig).then((result) => {
        if (result.value) {
          const uuids = item.clientPayments
            .filter((clientPayment) => clientPayment.status !== "ACCOUNTED")
            .map((clientPayment) => clientPayment.uuid);

          this.accountPayments(uuids);
        }
      });
    },
    accountPayments(uuids) {
      const promises = [];

      uuids.forEach((uuid) => {
        promises.push(this.accountPayment(uuid));
      });

      Promise.all(promises)
        .then(() => {
          notifySuccess(
            "Pagament comptabilitzat",
            "El pagament ha estat comptabilitzat correctament."
          );
        })
        .catch(() => {
          notifyError(
            "Pagament no comptabilitzat",
            "Hi ha hagut un error al comptabilitzat el pagament."
          );
        });
    },
    confirmAccountPayment(uuid) {
      this.$swal(this.confirmAccountPaymentAlertConfig).then((result) => {
        if (result.value) {
          this.accountPayment(uuid)
            .then(() => {
              notifySuccess(
                "Pagament comptabilitzat",
                "El pagament ha estat comptabilitzat correctament."
              );
            })
            .catch(() => {
              notifyError(
                "Pagament no comptabilitzat",
                "Hi ha hagut un error al comptabilitzat el pagament."
              );
            });
        }
      });
    },
    accountPayment(uuid) {
      return this.$store.dispatch("clientPayments/updatePayment", {
        uuid,
        status: "ACCOUNTED",
        accountedAt: formatDateObjectToDatabaseDateTime(new Date()),
      });
    },
    formatCurrency(amount) {
      return formatCurrency(amount);
    },
    getClientPaymentScopeName(code) {
      return getClientPaymentScopeName(code, this.$i18n.locale);
    },
    getClientPaymentStatusName(code) {
      return getClientPaymentStatusName(code, this.$i18n.locale);
    },
    getClientPaymentStatusColor(code) {
      switch (code) {
        case "VERIFIED":
          return "primary";
        default:
          return "info";
      }
    },
    getPaymentMethodName(code) {
      return getPaymentMethodName(code, this.$i18n.locale);
    },
    getBookingServiceName(bookingService) {
      if (!bookingService?.service) return null;
      return getServiceName(bookingService.service, this.$i18n.locale);
    },
    getBookingServicePvpPrice(bookingService, nights) {
      if (!bookingService?.pvpPrice) return null;
      return getBookingServiceClientTotalPriceText(
        bookingService,
        nights,
        this.$i18n.locale
      );
    },
    getBookingServiceCostPrice(bookingService, nights) {
      if (!bookingService?.costPrice) return null;
      return getBookingServiceOwnerTotalPriceText(
        bookingService,
        nights,
        this.$i18n.locale
      );
    },
    itemHasServicesDescriptions(item) {
      return item?.servicesDescriptions?.length || false;
    },
    itemHasTouristTaxDescription(item) {
      return item?.touristTaxDescriptions?.length || false;
    },
    isCurrentYear(dateString) {
      if (!dateString) return null;
      const currentYear = new Date().getFullYear();
      return dateString.includes(currentYear);
    },
    formatClientPaymentDate(date) {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return formatDateStringToDate(date, this.$i18n.locale, formatting);
    },
    updatePaymentsSums(payments) {
      if (!payments.length) {
        this.paymentsBookingSum = 0;
        this.paymentsSecurityDepositSum = 0;
        this.paymentsTouristTaxSum = 0;
        this.paymentsServicesSum = 0;
        this.paymentsTotalSum = 0;
        return;
      }

      this.paymentsBookingSum = 0;
      this.paymentsSecurityDepositSum = 0;
      this.paymentsTouristTaxSum = 0;
      this.paymentsServicesSum = 0;
      this.paymentsTotalSum = 0;

      payments.forEach((payment) => {
        this.paymentsBookingSum += payment.bookingSum || 0;
        this.paymentsSecurityDepositSum += payment.securityDepositSum || 0;
        this.paymentsTouristTaxSum += payment.touristTaxSum || 0;
        this.paymentsServicesSum += payment.servicesSum || 0;
        this.paymentsTotalSum += payment.totalSum || 0;
      });
    },
    onTableFiltered(filteredRows) {
      this.updatePaymentsSums(filteredRows);
    },
    getBillingType(booking) {
      return booking?.contract?.billingType || null;
    },
  },
};
</script>
